import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// vant的toast提示框组件，大家可根据自己的ui组件更改。


// 环境的切换
if (process.env.NODE_ENV == 'development'){
    axios.defaults.baseURL = 'http://hout.kehui.cloud/';
}

else if (process.env.NODE_ENV == 'debug'){
    axios.defaults.baseURL = 'http://hout.kehui.cloud/';
}

else if (process.env.NODE_ENV == 'production'){
    axios.defaults.baseURL = 'http://hout.kehui.cloud/';
}

// axios.defaults.baseURL='http://erp.zhichengshangxin.com/api/'
axios.defaults.timeout=8000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// Content-Type':'"multipart/form-data
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

//get请求
export function get(url, params){
    return new Promise((resolve, reject) =>{
    return  axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}