
import {get,post} from "./http"
//普通发布模式
export const shou= p => post('/api/kfy/shou', p); // 首页页面(科汇云数据中心)
export const demand_add= p => post('/api/demand/demand_add', p); // 需求普通发布接口
export const demand_title= p => post('/api/demand/title', p); // 需求普通发布接口返回需求领域
export const demand_type= p => post('/api/demand/type', p); // 需求普通发布接口返回需求类别
export const demand_area= p => post('/api/demand/area', p); // 需求普通发布接口地区
export const demand_urgency= p => post('/api/demand/urgency', p); // 返回紧急程度
// 底部数据
export const bottom= p => post('/api/category/bottom', p); // 返回紧急程度bottom
//机构云
export const employer_organ= p => post('/api/provider/school_achieves', p); // 返回紧急程度bottom
//机构云发布成果 api/demand/industrys
export const achievements= p => post('/api/achievements/achievements_add', p); // 返回紧急程度bottom
//机构云行业领域
export const industrys= p => post('/api/demand/industrys', p); // 行业领域

export const schoolachievements= p => post('/api/achievements/school_achievementy', p); // 高校 院所科研个人等服务商类型
//高校详情等
export const employer_xqs= p => post('/api/provider/employer_xqs', p); // 行业领域
//机构云在线咨询
export const message_add= p => post('/api/message/message_add', p); // 行业领域
//更多专家
export const expert_lists= p => post('/api/expert/expert_lists', p); // 行业领域
//专家详情
export const dexpert_xq= p => post('/api/expert/dexpert_xq', p); // 行业领域
//专家库入驻
export const expert_add= p => post('/api/expert/expert_add', p); // 行业领域
//最高学历
export const education= p => post('/api/expert/education', p); // 行业领域
//人才招聘
export const recruit_lists= p => post('/api/recruit/recruit_lists', p);
//专家所属单位
export const danwei= p => post('/api/expert/danwei', p); // 行业领域
//专家所属领域
export const lyu= p => post('/api/expert/lyu', p); // 行业领域

export const photo_list= p => post('/api/category/photo_list', p);
//返回学位
export const degree= p => post('/api/recruit/degree', p);
//返回行业领域
export const industry= p => post('/api/recruit/industry', p);
//返回企业性质
export const prise= p => post('/api/manager/prise', p);
//职位发布
export const recruit_add= p => post('/api/recruit/recruit_add', p);
//招聘人数
export const rens= p => post('/api/manager/rens ', p);
//招聘经验
export const experience= p => post('/api/manager/experience ', p);
//招聘详情
export const recruit_xq= p => post('/api/recruit/recruit_xq ', p);
//招用人单位详情
export const employer_list= p => post('/api/provider/employer_list ', p);
//招用人单位详情
export const manager_add= p => post('/api/manager/manager_add ', p);
//人才云主页
export const cloud= p => post('/api/manager/cloud ', p);
//人才云主页
export const school_photo= p => post('api/category/school_photo ', p);  //合作院校
//最新动态
export const Dynamics= p => post('api/policy/dynamics', p); 
//最新动态
export const school_news= p => post('api/policy/dynamic', p);  //合作院校
//科汇学院
export const lesson_type= p => post('api/lesson/lesson_type', p);  //合作院校
//科汇学院顶部
export const types_top= p => post('api/lesson/type', p);  //合作院校
//轮播图首页
export const lun_shou= p => post('/api/ad/shou', p);
//轮播图机构云
export const lun_gou= p => post('/api/ad/gou', p);
//轮播图人才云
export const lun_ren= p => post('/api/ad/ren', p);
//轮播图创新工具
export const lun_xin= p => post('/api/ad/xin', p);
//轮播图客服云
export const kefu= p => post('/api/ad/kefu', p);
//轮播图政府云
export const zhfu= p => post('/api/ad/zhfu', p);
//轮播图智产云
export const zhi= p => post('/api/ad/zhi', p);
//轮播图企业云
export const qi= p => post('/api/ad/qi', p);
//第七步
export const expertqi= p => post('api/expert/qi', p);
//第九步
export const expertjiu= p => post('api/expert/jiu', p);
//第九步
export const expertshi= p => post('api/expert/shi', p);
//十步法需求发布草稿接口
export const demand_draft= p => post('api/demand/demand_draft', p);
//十步法需求发布
export const demand_adds= p => post('api/demand/demand_adds', p);
// 草稿接口
export const Rdraft= p => post('api/demand/rdraft', p);

//需求类别
export const xu_type= p => post('api/demand/type', p);
//回新兴战略分类
export const zhan_type= p => post('api/achievements/xtype', p);
//返回成熟度
export const maturity= p => post('api/achievements/maturity', p);
//返回成果领域
export const territorys= p => post('api/achievements/territory', p);
//返回成果领域
export const bili= p => post('api/recruit/bili', p);
//返回成果领域
export const fen= p => post('api/recruit/fen', p);
//级别
export const jibie= p => post('api/policy/jibie', p);
//政策匹配
export const policy_system= p => post('api/policy/policy_system', p);
// 获取条数
export const policyp = p => post('api/recruit/policyp', p);
//成果合作方式
export const hmethods= p => post('api/achievements/hmethod', p);
//成果合作方式
export const demand_succes= p => post('api/demand/demand_success', p);
//服务云机构
export const displayes= p => post('api/server/display', p);
//服务云机构
export const article_list= p => post('api/provider/article_list', p);
//首页智产云
export const patent_list= p => post('api/patent/patent_list', p);
//首页智产云
export const achievements_listss= p => post('api/achievements/achievements_lists', p);
//首页智产云
export const recruit_xqs= p => post('api/recruit/recruit_xqs', p);
//首页科汇云数据
export const shuju= p => post('api/ad/shuju', p);
//首页科汇云数据
export const video_list= p => post('api/video/video_list', p);

//首页科汇云数据
export const dynamic_xq= p => post('api/policy/dynamic_xq', p);

//首页中试验证基地
export const shiyan_list= p => post('api/product/getIndex', p);
