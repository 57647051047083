<template>
	<div class="container">
		<div class="one_box">
			<div><el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="keywordss"></el-input></div>
			<div class="text_search" @click="input_search">搜索</div>
		</div>
		<div class="two_box">
			<!--按荣誉-->
			<div class="filter-list">
				<div class="filter-item">
					<div class="filter-key" style="float:left">按学位：</div>
					<div class="filter-values " :class="{ filtercollapse: whether }">
						<ul class="clear">
							<li :class="{ Active: Active == item.name }" v-for="(item, index) in structureList" @click="jie_gou(index, item)" :key="index">
								<a class="text-color" onclick="return false;">{{ item.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!--按领域-->
			<div class="filter-list">
				<div class="filter-item">
					<div class="filter-key" style="float:left">按领域：</div>
					<div class="filter-values " :class="{ filtercollapse: whether }">
						<ul class="clear">
							<li :class="{ Active_two: Active_two == item.name }" v-for="(item, index) in structureList_two" @click="jie_gou_two(index, item)" :key="index">
								<a class="text-color" onclick="return false;">{{ item.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!--所在区域-->
			<div class="filter-list">
				<div class="filter-item">
					<div class="filter-key" style="float:left">按所在区域：</div>
					<div class="filter-values " :class="{ three_box_ul: whether }">
						<ul class="clear ">
							<li :class="{ Active_three: Active_three == item.title }" v-for="(item, index) in structureList_three" @click="jie_gou_three(index, item)" :key="index">
								<a class="text-color" onclick="return false;">{{ item.title }}</a>
							</li>
						</ul>
					</div>

					<div class="filter-options" style="visibility: visible;">
						<a @click="addclass" href="javascript:;" class="j-filter-more filter-more">{{ name }}</a>
					</div>
				</div>
			</div>
			<div></div>
		</div>
		<div class="three_box">
			<div class="tabs">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="综合" name="first">
						<zhuanjia @total="getson" ref="child"></zhuanjia>
						<div class="ye">
							<el-pagination
								background
								@size-change="sizeChange"
								@current-change="currentChange"
								layout="total, prev, pager, next"
								:current-page.sync="pageNum"
								:page-size="pageSize"
								:total="total"
							></el-pagination>
						</div>
					</el-tab-pane>
					<el-tab-pane label="最新入驻排序" name="second">
						<zhuanjia ref="childs"></zhuanjia>
						<div class="ye">
							<el-pagination
								background
								@size-change="sizeChange"
								@current-change="currentChange"
								layout="total, prev, pager, next"
								:current-page.sync="pageNum"
								:page-size="pageSize"
								:total="total"
							></el-pagination>
						</div>
					</el-tab-pane>
					<el-tab-pane label="浏览量排序" name="third">
						<zhuanjia ref="childes"></zhuanjia>
						<div class="ye">
							<el-pagination
								background
								@size-change="sizeChange"
								@current-change="currentChange"
								layout="total, prev, pager, next"
								:current-page.sync="pageNum"
								:page-size="pageSize"
								:total="total"
							></el-pagination>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import zhuanjia from '../common/gd_zhuanjia';
import { demand_area, lyu, degree } from '../../common/js/api';
export default {
	name: 'scientist',
	components: {
		zhuanjia
	},
	data() {
		return {
			total: 0,
			pageSize: 4,
			pageNum: 1,
			name: '展开全部',
			whether: true,
			arrs: [],
			keywordss: '', //搜索单词
			activeName: 'first',
			jie: '',
			jie_two: '',
			jie_three: '',
			Active: '不限', //荣誉
			Active_two: '不限',
			Active_three: '不限',
			structureList: [],
			structureList_two: [],
			structureList_three: [],
			arr_area: [],
			educationid: '', //学位id
			lingid: '', //领域id
			province: '' //区域的id
		};
	},
	created() {
		// this.geng_duo()
		this.di_qv();
		this.ling_yu();
		this.xue_wei();
	},
	mounted() {
		this.input_search();
	},
	methods: {
		//上一页下一页按钮
		currentChange(val) {
			this.pageNum = val;
			this.input_search();
		},
		//每页显示几条数据按钮
		sizeChange(val) {
			this.pageSize = val;
			this.input_search();
		},
		getson(data) {
			this.total = data;
		},
		input_search() {
			console.log(this.educationid);
			var pageS = this.pageSize;
			var pageN = this.pageNum;
			var key = this.keywordss; //搜索单词
			var education = this.educationid; //学位
			var ling = this.lingid; //领域
			var province = this.province; //全区域id
			if (this.activeName == 'first') {
				var num1 = 1;
				var num2 = '';
				var num3 = '';
				this.$refs.child.geng_duo(key, ling, province, num1, num2, num3, education, pageS, pageN);
			} else if (this.activeName == 'second') {
				var num1 = '';
				var num2 = 2;
				var num3 = '';
				this.$refs.childs.geng_duo(key, ling, province, num1, num2, num3, education, pageS, pageN);
			} else if (this.activeName == 'third') {
				var num1 = '';
				var num2 = '';
				var num3 = 3;
				this.$refs.childes.geng_duo(key, ling, province, num1, num2, num3, education, pageS, pageN);
			}
		},
		//学位
		xue_wei() {
			degree({})
				.then(res => {
					for (var i = 0; i < res.data.length; i++) {
						Object.assign(res.data[i], { isSelected: false });
					}
					this.structureList = res.data;
				})
				.catch(err => {});
		},
		//研究领域
		ling_yu() {
			lyu({})
				.then(res => {
					for (var i = 0; i < res.data.length; i++) {
						Object.assign(res.data[i], { isSelected: false });
					}
					this.structureList_two = res.data;
				})
				.catch(err => {});
		},
		addclass() {
			if (this.whether == true) {
				this.whether = false;
				this.name = '收起';
			} else {
				this.whether = true;
				this.name = '展开全部';
			}
		},
		di_qv() {
			demand_area({})
				.then(res => {
					for (var i = 0; i < res.data.length; i++) {
						Object.assign(res.data[i], { isSelected: false });
					}
					this.structureList_three = res.data;
				})
				.catch(err => {});
		},

		handleClick(tab, event) {
			this.input_search();
		},
		jie_gou(index, item) {
			console.log(item);
			// 商标结构
			this.educationid = item.id;
			this.Active = item.name;
			this.jie = item.name;
			if (item.name === '不限') {
				this.jie = '';
			}
			this.input_search();
		},
		jie_gou_two(index, item) {
			// 商标结构
			this.lingid = item.id;
			this.Active_two = item.name;
			this.jie_two = item.name;
			if (item.name === '不限') {
				this.jie = '';
			}
			this.input_search();
		},
		jie_gou_three(index, item) {
			// 商标结构
			this.province = item.id;
			this.Active_three = item.title;
			this.jie_three = item.title;
			if (item.title === '不限') {
				this.jie_three = '';
			}
			this.input_search();
		}
	}
};
</script>

<style scoped lang="less">
/deep/.el-input__icon {
	line-height: 35px;
}
.ye {
	width: 1200px;
	margin: 30px 33%;
}
.three_box_ul {
	height: 50px;
	overflow: hidden;
}
/deep/.el-tabs__active-bar {
	background-color: unset;
}
/deep/.el-tabs__nav {
	position: unset !important;
}
/deep/.el-tabs__nav-wrap {
	padding: 15px;
	background-color: #f7f7f7;
}
/deep/.el-tabs__nav-wrap::after {
	background-color: #fff;
}
/deep/.el-input {
	margin-left: 30px;
}
/deep/.el-input__inner {
	width: 1074px;
	height: 34px;
	border-radius: 53px;
	background-color: rgba(255, 255, 255, 100);
	/*color: rgba(223, 219, 219, 100);*/
	font-size: 13px;
	text-align: left;
}
.container {
	background-color: #f8f9fa;
	width: 100%;
	height: auto;
	overflow: hidden;
	margin-top: 135px;
	.two_box {
		margin: 0 auto;
		background-color: #fff;
		width: 1200px;
		height: auto;
		line-height: 20px;
		border-radius: 0px 5px 5px 0px;
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		padding-bottom: 15px;
	}
	.three_box {
		overflow: hidden;
		margin: 30px auto;
		width: 1200px;
		background-color: #fff;
		height: 780px;
		line-height: 20px;
		border-radius: 0px 5px 5px 0px;
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		.tabs {
			margin: 15px;
		}
	}
	.text_search {
		cursor: pointer;
		margin-left: 30px;
		color: rgba(247, 247, 247, 100);
		font-size: 14px;
	}
	.one_box {
		width: 1200px;
		margin: 15px auto;
		height: 53px;
		line-height: 23px;
		background-color: rgba(56, 146, 235, 100);
		color: rgba(51, 51, 51, 100);
		font-size: 16px;
		display: flex;
		align-items: center;
	}
}
.filter-item {
	padding-top: 15px;
	position: relative;
	.filter-key {
		/*float: left;*/
		width: 56px;
		padding-left: 30px;
		padding-right: 30px;
		white-space: nowrap;
		/*overflow: hidden;*/
		margin-top: 15px;
		height: 20px;
		line-height: 20px;
		color: #101010;
		cursor: default;
	}
	.filter-values {
		margin-left: 150px;
		margin-right: 110px;
		/*border-bottom: 1px #f0f0f0 dashed;*/
		color: #b1afaf;
		transition: all 0.3s;
	}
	.filtercollapse {
		height: 35px;
		overflow: hidden;
	}
	.filter-options {
		position: absolute;
		right: 30px;
		top: 24px;
		visibility: hidden;
		.filter-more {
			padding-right: 16px;
			color: #999;
			line-height: 16px;
			display: inline-block;
		}
	}
}
.filter-list .Active a {
	padding: 5px 10px;
	color: #ffffff;
	background: #3892eb;
	font-size: 14px;
	/*color: #00bb00;*/
}
.filter-list .Active_two a {
	padding: 5px 10px;
	font-size: 14px;
	color: #ffffff;
	background: #3892eb;
	/*color: #00bb00;*/
}
.filter-list .Active_three a {
	padding: 5px 10px;
	font-size: 14px;
	color: #ffffff;
	background: #3892eb;
	/*color: #00bb00;*/
}
.filter-list li {
	float: left;
	margin: 15px 20px 15px 0px;
	height: 20px;
	line-height: 20px;
	cursor: pointer;
}
.cf:after,
.clear:after {
	content: '';
	display: block;
	height: 0;
	visibility: hidden;
	clear: both;
}
.text-color {
	color: #b1afaf;
	font-size: 14px;
}
@media screen and (max-width: 1200px) {
	.container{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.container .one_box{
		width: 98%;
		height: 4rem;
	}
	.container .two_box{
		width: 98%;
	}
	 /deep/.el-input__inner{
		 width: 40rem;
		 height: 3rem;
		font-size: 1.8rem;
	 }
	 .container .text_search{
		 font-size: 1.7rem;
	 }
	 /deep/.el-input__prefix{
		 display: none;
	 }
	 .ye{
		 width: 98%;
		 margin-left: 20%;
	 }
	 .filter-item .filter-key{
		 font-size: 1.6rem;
		 width: 12rem;
		 line-height: 2.3rem;
	 }
	 .text-color{
		 font-size: 1.4rem;
		 line-height: 2.3rem;
	 }
	 .filter-list li{
		 height: 2.3rem;
		 line-height: 2.3rem;
	 }
	 .filter-item .filtercollapse{
		 height: auto;
	 }
	 .filter-item .filter-options .filter-more{
		 font-size: 1.4rem;
		 padding-top: 1rem;
	 }
	 /deep/.el-tabs__item{
		 font-size: 1.6rem;
	 }
	 /deep/.containers .one_box .title{
		 font-size: 1.6rem;
		 padding: 1.5rem 0.5rem;
	 }
	 /deep/.containers .one_box .two_box{
		 font-size: 1.4rem;
	 }
	 /deep/.el-icon-school{
		 font-size: 1.4rem;
	 }
	 /deep/.containers .one_box .three_box{
		 font-size: 1.4rem;
	 }
}
</style>
