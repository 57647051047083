<template>
	<div class="containers">
		<div class="one_box" @click="zhuan_detail(item.id)" v-for="(item, index) in arrs" :key="index">
			<div class="title">{{ item.name }}</div>
			<div class="two_box">
				<div class="icon_box">
					<i class="el-icon-school"></i>
					<span>{{ item.company_name }}</span>
				</div>
				<div class="icon_box">
					<i class="el-icon-menu"></i>
					<span>{{ item.research.name }}</span>
				</div>
			</div>
			<div class="three_box zhuan_content">
				<span>专家介绍：</span>
				<span>{{ item.resume }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { expert_lists } from '../../common/js/api';
export default {
	name: 'gd_zhuanjia',
	props: [],
	data() {
		return {
			arrs: [],
			total: 0,
			pageSize: 4,
			pageNum: 1
		};
	},
	created() {},
	methods: {
		zhuan_detail(ids) {
			this.$router.push({
				path: '/expert',
				query: {
					id: ids
				}
			});
		},

		//更多专家
		geng_duo(key, ling, province, num1, num2, num3, education, pageS, pageN) {
			expert_lists({
				default: num1, //综合
				province: province,
				research: ling, //领域
				keywords: key, //搜索词
				views: num3, //浏览量排序
				time: num2, //最新入驻排序
				page: pageN,
				limits: pageS,
				education: education
			})
				.then(res => {
					this.total = res.data.total;
					this.$emit('total', this.total);
					this.arrs = res.data.data;
					console.log(this.arrs);
				})
				.catch(err => {});
		}
	}
};
</script>

<style scoped lang="less">
.el-icon-school {
	font-size: 14px;
	color: #3892eb;
}
.el-icon-menu {
	font-size: 14px;
	color: #3892eb;
}
.containers {
	.ye {
		width: 1200px;
		margin: 30px 33%;
	}
	.one_box {
		cursor: pointer;
		width: 100%;
		height: 150px;
		margin: 0 auto;
		border-bottom: 1px solid rgba(237, 237, 237, 100);
		.title {
			font-weight: 600;
			color: rgba(16, 16, 16, 100);
			font-size: 18px;
			padding: 15px;
		}
		.two_box {
			color: rgba(16, 16, 16, 100);
			font-size: 14px;
			padding: 0 15px;
			display: flex;
			.icon_box {
				margin-right: 30px;
			}
		}
		.three_box {
			//两行
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			line-height: 25px;
			padding: 15px;
			color: rgba(16, 16, 16, 100);
			font-size: 14px;
		}
	}
}
</style>
